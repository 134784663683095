import regexUtil from '@/utils/regex';
import {
  createValidationObjectSchema,
  formatMatches,
} from '@/utils/validations';

export const ProfileSchema = createValidationObjectSchema({
  first_name: { required: true },
  last_name: { required: true },
  timezone: { required: false },
  mobile_number: {
    required: false,
    match: formatMatches.mobile.aus,
  },
  date_of_birth: { required: true },
  email: { format: 'email', required: true },
  address_line: {},
  barangay: {},
  city: {},
  state: {},
  post_code: {
    match: [regexUtil.postalCode.php, 'XXXX', true],
  },
  country: { required: true },
});

export const NewPasswordSchema = createValidationObjectSchema({
  password: {
    required: true,
    match: [
      regexUtil.password,
      'Password at least 8 characters, has a Uppercase, lowercase, numbers, special char., (i.e., Password123!)',
    ],
  },
  password_confirmation: {
    required: true,
    oneOf: ['password', 'Passwords must match'],
  },
});

export const ChangePasswordSchema = createValidationObjectSchema({
  current_password: {
    required: true,
    match: [
      regexUtil.password,
      'Password at least 8 characters, has a Uppercase, lowercase, numbers, special char., (i.e., Password123!)',
    ],
  },
  password: {
    required: true,
    match: [
      regexUtil.password,
      'Password at least 8 characters, has a Uppercase, lowercase, numbers, special char., (i.e., Password123!)',
    ],
  },
  password_confirmation: {
    required: true,
    oneOf: ['password', 'Passwords must match'],
  },
});

export const MobileVerificationSchema = createValidationObjectSchema({
  mobile_number: {
    required: true,
    match: formatMatches.mobile.aus,
  },
  code: { required: true },
});
