
import { defineComponent } from 'vue';
import FormField from '@/components/forms/FormField.vue';
import { Form } from 'vee-validate';
import { ChangePasswordSchema } from '@/schemas/profile.schema';
const initialValues = {} as unknown as any;

export default defineComponent({
  emits: ['submitForm'],
  name: 'change-password-form',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    // eslint-disable-next-line
    options: [] as any,
    formData: initialValues,
    form: {} as unknown as typeof Form,
  }),
  mounted() {
    this.form = this.$refs.changePassForm as typeof Form;
  },
  components: {
    FormField,
    Form,
  },
  computed: {
    formSchema() {
      return ChangePasswordSchema;
    },
  },
  methods: {
    submitForm(values) {
      const formData = {
        ...this.formData,
        ...values,
      };

      this.resetForm();

      this.$emit('submitForm', formData);
    },
    resetForm() {
      this.form.resetForm();

      // Reset the form values
      this.formData = initialValues;
    },
  },
});
