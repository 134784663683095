
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { Actions, Getters } from '@/store/enums/AuthEnums';

import NewPasswordForm from '@/components/forms/profile/CreateNewPasswordForm.vue';
import ChangePasswordForm from '@/components/forms/profile/ChangePasswordForm.vue';

import toasts from '@/utils/toasts';
import Navigator from '@/views/new-design/layout/Navigator.vue';
import { Form } from 'vee-validate';

export default defineComponent({
  name: 'password-settings-page',
  components: {
    Navigator,
    ChangePasswordForm,
    NewPasswordForm,
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters({
      authUser: Getters.GET_AUTH_USER,
      actionError: Getters.GET_AUTH_USER_ACTION_ERROR,
    }),
  },
  methods: {
    ...mapActions({
      saveNewPassword: Actions.NEW_PASSWORD,
      changePassword: Actions.CHANGE_PASSWORD,
    }),
    handleSubmitForm(values) {
      return this.authUser.create_new_password
        ? this.handleSaveNewPassword(values)
        : this.handleChangePassword(values);
    },
    handleSaveNewPassword(values) {
      if (this.loading) return;
      this.loading = true;

      return this.saveNewPassword(values)
        .then(() => {
          (this.$refs.newPasswordForm as typeof Form).resetForm();
          toasts.success('Password successfully created');
        })
        .catch(() => {
          const { errors, messages } = this.actionError;
          toasts.error(errors, messages);
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        });
    },
    handleChangePassword(values) {
      if (this.loading) return;
      this.loading = true;

      return this.changePassword(values)
        .then(() => {
          (this.$refs.changePasswordForm as typeof Form).resetForm();
          toasts.success('Password successfully modified');
        })
        .catch(() => {
          const { errors, messages } = this.actionError;
          toasts.error(errors, messages);
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        });
    },
  },
});
